import logo from './logo.svg';
import './App.css';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";





function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyANgeroBFD4lsP-yi-Ou58V0z2ha99zyr8"
  });
  const center = useMemo(() => ({ lat: 47.643132227534444, lng: 16.668917910615196 }), []);
  return (
    <main className="header-bg">
      <header>
        <div className="navbar">
          <div className="title">
            <div className='title2'>
              Szentgróti Autómentés - Autóbérlés Sopron
            </div>
            <div className="call">
              <a href="tel:+36704141440">+36704141440</a>
            </div>
          </div>
        </div>
      </header>

      <section className="container">
        <div className = "image-text">
          <div className = "image">
            <img className="img1" src = {require ('../src/assets/first.jpg')} alt = "Autómentés" />
            <img className="img2" src = {require ('../src/assets/kav.jpg')} alt = "Bizonyítvány" />
          </div>
            <div class='text-on-image'>
              <p>Tel: <a href="tel:+36704141440">+36704141440</a></p>
              <p>E-mail: szentgrotimarton@gmail.com</p>
              <p>Szentgróti Autómentés - Autóbérlés - Autótárolás 0-24H </p>
            </div>
        </div>
        <div className="ejjelnappal">
          <h1>Autómentés Sopron 0-24H Éjjel - Nappal</h1>
        </div>
      </section>

    <section id="features">
      <div className="container features">
        <div className="features-content">
          <div className="features-left">
            <img src = {require ('../src/assets/second.jpg')} alt = "Autómentés Autó" />
          </div>
          <div className="features-right">
            <span className='repair-header'>
              Miért válassz minket?
            </span>
            <p className="repair-text">
              Éjjel nappal hívható, hétvégén valamint ünnepnapokon is!
               Főként Magyarország és Ausztria területén. Lerobbant/sérült autóját igény esetén telephelyünkön 
               tároljuk kamerával megfigyelt, őrzött területen, számlásan magán személyként. 
               Tárolás díjak: fedett részen 4500 Ft/nap, fedetlen részen 3000 Ft/nap.
               Lerobbant, törött, műszaki hibás autóját elszállítjuk, helyette igény szerint azonnal bérautót biztosítunk 
               a tovább haladás érdekében a megadott helyszínre (plusz költséget von maga után).
            </p>
            <p className="repair2-text">
              Helyszínen: Autó bikázás, akkumulátor csere, kerék csere, kisebb műszaki javítások.
            </p>
            <p className="repair3-text">
              Árajánlat esetén hívja az alábbi telefon számot a nap bármely szakában, baleset esetén: <a className="call-red" href="tel:+36704141440">+36704141440</a>
            </p>
          </div>
        </div>
      </div>
    </section>


    


    <section id="rent">
      <div className="ejjelnappal2">
        <h1>Autóbérlés Sopron</h1>
      </div>
      <div className="container features">
        <div className="features-content">
          <div className="features-left2">
            <img src = {require ('../src/assets/honda.jpg')} alt = "Honda autó" />
          </div>
          <div className="features-middle">
            <img src = {require ('../src/assets/kia.jpg')} alt = "Kia autó" />
          </div>
          <div className="features-right">
            <span className='repair-header2'>
              Autók felszereltsége:
            </span>
            <p className="repair2-text">
              <ul>
                <li>Szervókormány</li>
                <li>Elektromos ablak</li>
                <li>Rádió, AUX, USB, Bluetooth</li>
                <li>Központi zár</li>
                <li>Airbag (vezető - utas)</li>
                <li>ABS</li>
                <li>Klíma</li>
              </ul>
            </p>
            <p className="repair3-text">
              Az autók rendszeresen karbantartott, szervízelt, tiszta, nem dohányzó, műszakilag megfelelő állapotban vannak.<br/>
              <br/>
              A pontos feltételeket, valamint az autóbérléssel kapcsolatos összes fontos információt a bérleti szerződés tartalmazza, amely az alábbi linken megtekinthető:<br/>
              <p className='szerzodes'><a href='/szerzodes.pdf' target='_blank'> Autóbérleti szerződés</a></p>
            </p>
          </div>
        </div>
        <div className='rent-content'>
        <span className='repair-header2'>
              Mire van szükség az autó bérléséhez?
            </span>
            <p className="repair2-text">
              Az autót bérelheti 21. életévét betöltött személy, aki rendelkezik legalább egy éves 
              vezetői engedéllyel és tapasztalattal.
            </p>
            <p className="repair2-text">
              <span className='doc'>Szükséges dokumentumok:</span>
              <ol>
                <li>Érvényes személyazonosító igazolvány</li>
                <li>Lakcímet igazoló hatósági igazolvány</li>
                <li>Vezetői engedély</li>
              </ol>
            </p>
        </div>
        <div className='rent-content'>
        <span className='repair-header2'>
              Díjak - Az árak bruttó árak!
            </span>
            <p className="repair2-text">
              <span className="doc">Bérleti díjszámítás:</span><br/>
              1-3 nap 7500 Ft/nap<br/>
              4-10 nap 6000 Ft/nap<br/>
              11 naptól 5000 Ft/nap<br/>
              <br/>
              <span className='doc'>Kilométer - korlátozás:</span><br/>
              1-3 nap 300km/nap<br/>
              4-10 nap 200km/nap<br/>
              11 naptól 150km/nap<br/>
              1 hónap 4000km/hónap<br/>
              <br/>
              Túlfutás esetén: +40 Ft/km
              <br/>
              Euróval való fizetés esetén 350 Ft / 1 €

            </p>
        </div>
      </div>
    </section>



      <div className='App'>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={12}
          >  
          <MarkerF position={{ lat: 47.643132227534444, lng: 16.668917910615196 }} />
          </GoogleMap>
        )}
      </div>
      <footer id='footer'>
            <div className='container footer'>
                <div className='footer-box1'>
                    <p><a href='/szerzodes.pdf' target='_blank'>&bull; Autóbérleti szerződés</a></p>
                </div>
                <div className='footer-box2'>
                    <p>
                        Szentgróti Autómentés - Autóbérlés Sopron<br /><br/>
                        0 - 24H <br /><br/>
                        9494 Sopron-Balf, Pilerin utca 14/A<br />
                    </p>
                </div>
                <div className='footer-box3'>
                  <p><a className='footer-link' href='https://www.facebook.com/profile.php?id=100079917001471' target='_blank'>Facebook oldal</a><br/><br/>
                  Email: szentgrotimarton@gmail.com<br/><br/>
                  Telefon: <a href="tel:+36704141440">+36704141440</a>
                  </p>
                </div>
            </div>
        </footer>

    </main>
  );
}

export default App;
